var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.showDialog},on:{"close":_vm.close,"open":_vm.getData}},[_c('v-row',{attrs:{"dense":""}},[(_vm.records.length > 0)?_c('v-col',{attrs:{"cols":"12","xl":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("FECHA DE PAGO")]),_c('th',[_vm._v("MÉTODO DE PAGO")]),_c('th',[_vm._v("REFERENCIA")]),_c('th',{staticClass:"text-right"},[_vm._v("MONTO")]),_c('th',{staticClass:"text-right"},[_vm._v("ACCIONES")])])]),_c('tbody',_vm._l((_vm.records),function(row,index){return _c('tr',{key:index},[(row.id)?[_c('td',[_vm._v(_vm._s(row.date_of_payment))]),_c('td',[_vm._v(" "+_vm._s(row.payment_method_type_description)+" ")]),_c('td',[_vm._v(_vm._s(row.reference))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(row.payment)+" ")]),_c('td',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"error","dark":"","fab":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.clickDelete(row.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]:[_c('td',[_c('div',{staticClass:"form-group mb-0",class:{
                      'has-danger': row.errors.date_of_payment,
                    }},[_c('el-date-picker',{attrs:{"type":"date","clearable":false,"format":"dd/MM/yyyy","value-format":"yyyy-MM-dd"},model:{value:(row.date_of_payment),callback:function ($$v) {_vm.$set(row, "date_of_payment", $$v)},expression:"row.date_of_payment"}}),(row.errors.date_of_payment)?_c('small',{staticClass:"form-control-feedback",domProps:{"textContent":_vm._s(row.errors.date_of_payment[0])}}):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"form-group mb-0",class:{
                      'has-danger': row.errors.payment_method_type_id,
                    }},[_c('el-select',{model:{value:(row.payment_method_type_id),callback:function ($$v) {_vm.$set(row, "payment_method_type_id", $$v)},expression:"row.payment_method_type_id"}},_vm._l((_vm.payment_method_types),function(option){return _c('el-option',{key:option.id,attrs:{"value":option.id,"label":option.name}})}),1),(row.errors.payment_method_type_id)?_c('small',{staticClass:"form-control-feedback",domProps:{"textContent":_vm._s(row.errors.payment_method_type_id[0])}}):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"form-group mb-0",class:{
                      'has-danger': row.errors.reference,
                    }},[_c('el-input',{model:{value:(row.reference),callback:function ($$v) {_vm.$set(row, "reference", $$v)},expression:"row.reference"}}),(row.errors.reference)?_c('small',{staticClass:"form-control-feedback",domProps:{"textContent":_vm._s(row.errors.reference[0])}}):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"form-group mb-0",class:{
                      'has-danger': row.errors.payment,
                    }},[_c('el-input',{staticClass:"input-text-right",model:{value:(row.payment),callback:function ($$v) {_vm.$set(row, "payment", $$v)},expression:"row.payment"}}),(row.errors.payment)?_c('small',{staticClass:"form-control-feedback",domProps:{"textContent":_vm._s(row.errors.payment[0])}}):_vm._e()],1)]),_c('td',[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"success","dark":"","fab":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.clickSubmit(index)}}},[_c('v-icon',[_vm._v("mdi-check-all")])],1),_c('v-btn',{attrs:{"color":"error","dark":"","fab":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.clickCancel(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]],2)}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" TOTAL A PAGAR ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.document.total)+" ")]),_c('td')]),_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" TOTAL PAGADO ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.document.total_paid)+" ")]),_c('td')]),_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" SALDO ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.document.total_difference)+" ")]),_c('td')])])]},proxy:true}],null,false,232242291)})],1):_vm._e(),(_vm.showAddButton && _vm.document.total_difference > 0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","xl":"12"}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.clickAddRow}},[_vm._v("Nuevo")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <el-dialog
    :title="title"
    :visible="showDialog"
    @close="close"
    @open="getData"
  >
    <v-row dense>
      <v-col cols="12" xl="12" v-if="records.length > 0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>FECHA DE PAGO</th>
                <th>MÉTODO DE PAGO</th>
                <th>REFERENCIA</th>
                <th class="text-right">MONTO</th>
                <th class="text-right">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in records" :key="index">
                <template v-if="row.id">
                  <td>{{ row.date_of_payment }}</td>
                  <td>
                    {{ row.payment_method_type_description }}
                  </td>
                  <td>{{ row.reference }}</td>
                  <td class="text-right">
                    {{ row.payment }}
                  </td>
                  <td class="text-right">
                      <v-btn
                        color="error"
                        dark
                        @click.prevent="clickDelete(row.id)"
                        fab
                        x-small
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div
                      class="form-group mb-0"
                      :class="{
                        'has-danger': row.errors.date_of_payment,
                      }"
                    >
                      <el-date-picker
                        v-model="row.date_of_payment"
                        type="date"
                        :clearable="false"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      ></el-date-picker>
                      <small
                        class="
                                                        form-control-feedback
                                                    "
                        v-if="row.errors.date_of_payment"
                        v-text="row.errors.date_of_payment[0]"
                      ></small>
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-group mb-0"
                      :class="{
                        'has-danger': row.errors.payment_method_type_id,
                      }"
                    >
                      <el-select v-model="row.payment_method_type_id">
                        <el-option
                          v-for="option in payment_method_types"
                          :key="option.id"
                          :value="option.id"
                          :label="option.name"
                        ></el-option>
                      </el-select>
                      <small
                        class="
                                                        form-control-feedback
                                                    "
                        v-if="row.errors.payment_method_type_id"
                        v-text="row.errors.payment_method_type_id[0]"
                      ></small>
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-group mb-0"
                      :class="{
                        'has-danger': row.errors.reference,
                      }"
                    >
                      <el-input v-model="row.reference"></el-input>
                      <small
                        class="
                                                        form-control-feedback
                                                    "
                        v-if="row.errors.reference"
                        v-text="row.errors.reference[0]"
                      ></small>
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-group mb-0"
                      :class="{
                        'has-danger': row.errors.payment,
                      }"
                    >
                      <el-input
                        v-model="row.payment"
                        class="input-text-right"
                      ></el-input>
                      <small
                        class="
                                                        form-control-feedback
                                                    "
                        v-if="row.errors.payment"
                        v-text="row.errors.payment[0]"
                      ></small>
                    </div>
                  </td>
                  <td>
                    <v-btn
                      color="success"
                      dark
                      @click.prevent="clickSubmit(index)"
                      fab
                      x-small
                      class="mr-1"
                    >
                      <v-icon>mdi-check-all</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      dark
                      @click.prevent="clickCancel(index)"
                      fab
                      x-small
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </td>
                </template>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" class="text-right">
                  TOTAL A PAGAR
                </td>
                <td class="text-right">
                  {{ document.total }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3" class="text-right">
                  TOTAL PAGADO
                </td>
                <td class="text-right">
                  {{ document.total_paid }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3" class="text-right">
                  SALDO
                </td>
                <td class="text-right">
                  {{ document.total_difference }}
                </td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        cols="12"
        xl="12"
        v-if="showAddButton && document.total_difference > 0"
        class="text-center"
      >
        <el-button type="primary" icon="el-icon-plus" @click="clickAddRow"
          >Nuevo</el-button
        >
      </v-col>
    </v-row>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import { deletable } from "Mixins/deletable";

export default {
  props: ["showDialog", "documentId"],
  mixins: [deletable],
  data() {
    return {
      title: null,
      resource: "document-payments",
      records: [],
      payment_method_types: [],
      showAddButton: true,
      document: {},
    };
  },
  async created() {
    await this.initForm();
    await this.$http.get(`/${this.resource}/tables`).then((response) => {
      this.payment_method_types = response.data.payment_method_types;
      //this.initDocumentTypes()
    });
  },
  methods: {
    initForm() {
      this.records = [];
      this.showAddButton = true;
    },
    async getData() {
      this.initForm();
      await this.$http
        .get(`/${this.resource}/document/${this.documentId}`)
        .then((response) => {
          this.document = response.data;
          this.title = "Pagos del comprobante: " + this.document.number_full;
        });
      await this.$http
        .get(`/${this.resource}/payments/${this.documentId}`)
        .then((response) => {
          this.records = response.data.data;
        });
    },
    clickAddRow() {
      this.records.push({
        id: null,
        date_of_payment: dayjs().format("YYYY-MM-DD"),
        payment_method_type_id: null,
        reference: null,
        payment: 0,
        errors: {},
        loading: false,
      });
      this.showAddButton = false;
    },
    clickCancel(index) {
      this.records.splice(index, 1);
      this.showAddButton = true;
    },
    clickSubmit(index) {
      if (this.records[index].payment <= 0) {
        this.$message.error(
          "El monto no puede ser menor o igual a cero, verifique."
        );
        return;
      }

      if (
        this.records[index].payment > parseFloat(this.document.total_difference)
      ) {
        this.$message.error(
          "El monto ingresado supera al monto pendiente de pago, verifique."
        );
        return;
      }


      let form = {
        id: this.records[index].id,
        document_id: this.documentId,
        date_of_payment: this.records[index].date_of_payment,
        payment_method_type_id: this.records[index].payment_method_type_id,
        reference: this.records[index].reference,
        payment: this.records[index].payment,
      };
      this.$http
        .post(`/${this.resource}`, form)
        .then((response) => {
          if (response.data.success) {
            this.$message.success(response.data.message);
            this.getData();
            this.$eventHub.$emit('reloadData', this.resource);
            this.showAddButton = true;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.records[index].errors = error.response.data;
          } else {
            console.log(error);
          }
        });
    },
    close() {
      this.$emit("update:showDialog", false);
    },
    clickDelete(id) {
      this.destroy(`/${this.resource}/${id}`).then(
        () => this.getData()
      );
    },
  },
};
</script>

<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Ventas por cobrar</h5>
			</div>
			<div class="action-btn-wrap">
				
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table-without-filter :resource="resource">
				<tr slot="heading">
					<th class="text-right">N°</th>
					<th class="text-center">FECHA DE EMISIÓN</th>
					<th>COMPROBANTE</th>
					<th class="text-right">NÚMERO</th>
					<th class="text-right">RUC, DNI</th>
					<th>DENOMINACIÓN</th>
					<th class="text-center">M.</th>
					<th class="text-right">TOTAL VENTA</th>
					<th class="text-right">TOTAL PAGADO</th>
					<th class="text-right">SALDO</th>
					<th class="text-center">ACCIONES</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td class="text-right">{{ index }}</td>
					<td class="text-center">{{ row.date_of_issue }}</td>
					<td>{{ row.document_type_description }}</td>
					<td class="text-right">
						{{ row.number }}
					</td>
					<td class="text-right ">{{ row.customer_number }}</td>
					<td>{{ row.customer_name }}</td>
					<td class="text-center">
						{{ row.currency_type_id == 'PEN' ? 'S/' : '$' }}
					</td>
					<td class="text-right">{{ row.total }}</td>
					<td class="text-right">{{ row.total_paid }}</td>
					<td class="text-right">{{ row.total_debt }}</td>
					<td class="text-center">
						<v-btn
                            color="accent"
                            dark
                            @click.prevent="clickPayment(row.id)"
                            small
                        >
                            Cobrar
                        </v-btn>
					</td>
				</tr>
			</emb-data-table-without-filter>
		</v-card>

		<document-payments 
            :showDialog.sync="showDialogPayments"
            :documentId="recordId"
        >
        </document-payments>
	</div>
</template>

<script>
import DocumentPayments from './Component/Payments';

export default {
	data() {
		return {
			showDialogPayments: false,
			resource: 'document-payments',
			recordId: null,
			records: [],
		};
	},
	components: {
		DocumentPayments,
	},
	methods: {
		clickPayment(recordId) {
			this.recordId = recordId;
			this.showDialogPayments = true;
		},
		 
	},
};
</script>
